export const PAYMENT_TERM_OPTIONS: Array<{ label: string; value: number }> = [
  { label: 'Net 7', value: 7 },
  { label: 'Net 14', value: 14 },
  { label: 'Net 30', value: 30 },
  { label: 'Net 45', value: 45 },
  { label: 'Net 60', value: 60 },
  { label: 'Net 90', value: 90 }
]

export const  PAYMENT_TERM_OPTIONS_WITH_NULL: Array<{ label: string; value: number }> = [
  { label: '', value: null },
  ...PAYMENT_TERM_OPTIONS
]
